// based on https://github.com/integrations/html-to-mrkdwn

// const TurndownService = require('turndown');
import TurndownService from 'turndown';
import { strikethrough, taskListItems } from 'turndown-plugin-gfm';
import rules from './rules';

const emptyLine = '$_$_EMPTY_$_$';
const service = new TurndownService({
  bulletListMarker: '•',
  strongDelimiter: '*',
  emDelimiter: '_',
  codeBlockStyle: 'fenced',
  linkStyle: 'slack',
  blankReplacement(content, node) {
    const types = ['P'];
    if (types.indexOf(node.nodeName) !== -1) {
      return emptyLine;
    }
    return '';
  },
});

// override escaping
// https://api.slack.com/docs/message-formatting#how_to_escape_characters
service.escape = (string) =>
  string.replace('&', '&amp;').replace('<', '&lt;').replace('>', '&gt;');

service.use(strikethrough);
service.use(taskListItems);

service.remove('table');

Object.keys(rules).forEach((rule) => service.addRule(rule, rules[rule]));

export default (html) => {
  return {
    text: service
      .turndown(html)
      .replaceAll('\n' + emptyLine + '\n', emptyLine)
      .replaceAll(emptyLine, '\n'),
  };
};
