<template>
  <div
    class="flex items-center justify-center w-full main-wrapper"
    :class="disabled ? 'opacity-50' : ''"
  >
    <label class="flex items-center cursor-pointer group">
      <div class="relative">
        <input
          type="radio"
          class="hidden"
          :value="filterName"
          v-model="radioButtonValue"
          :disabled="disabled"
        />
        <div
          class="flex checkbox-line rounded-full border-2 border-solid border-grey5 group-hover:border-grey4"
          style="width: 1.15rem; height: 1.15rem"
        ></div>
      </div>
      <div
        class="text-grey3 ml-1 mr-3 lg:ml-2 lg:mr-4 font-medium dark:hover:text-white"
      >
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MRadio',
  props: {
    value: {
      type: String,
      required: true,
    },
    // name: {
    //   type: String,
    //   required: true,
    // },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterName: {
      type: String,
      required: true,
      default: '',
    },
    segmentName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    radioButtonValue: {
      get: function () {
        return this.value;
      },
      set: function () {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit('change', this.filterName);

        if (this.segmentName && window.meetric?.track) {
          const props = {
            source: this.$parent?.$parent?.$vnode?.tag || '',
            label: this.filterName,
            value: true, // just so it is same as checkbox
          };
          window.meetric.track(this.segmentName, props);
        }
      },
    },
  },
};
</script>

<style scoped>
input:checked ~ .checkbox-line {
  @apply border-base2;
  border-width: 0.32rem;
}
</style>
