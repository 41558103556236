<template>
  <div class="flex items-center justify-center w-full">
    <!-- Toggle Button -->
    <label class="flex items-center cursor-pointer">
      <!-- toggle -->
      <div class="relative">
        <!-- input -->
        <input
          :disabled="readonly"
          type="checkbox"
          class="hidden"
          v-bind:checked="value"
          v-on:input="onInput($event.target.checked)"
        />
        <!-- line -->
        <div
          class="toggle__line2 rounded-xl bg-base4 flex dark:text-grey2 border-grey5 border lg:border-0"
          :style="`width:${widthInRem}rem; height:${heightInRem}rem;`"
        >
          <div
            class="my-auto z-10 text-center"
            :class="readonly ? 'opacity-50' : ''"
            :style="`width:${widthInRem / 2}rem;`"
          >
            <slot name="leftFalse"></slot>
          </div>
          <div
            class="my-auto z-10 text-center"
            :class="readonly ? 'opacity-50' : ''"
            :style="`width:${widthInRem / 2}rem;`"
          >
            <slot name="rightTrue"></slot>
          </div>
        </div>
        <!-- dot -->
        <div
          class="dot-shadow toggle__dot2 absolute bg-white rounded-xl flex items-center z-5 border-grey6 border"
        ></div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MOptionsToggle',
  props: {
    widthInRem: {
      type: Number,
      required: false,
      default: 10,
    },
    heightInRem: {
      type: Number,
      required: false,
      default: 2.25,
    },
    value: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    segmentName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onInput(checked) {
      if (this.readonly) return;

      this.$emit('input', checked);

      if (this.segmentName && window.meetric?.track) {
        const props = {
          source: this.$parent?.$parent?.$vnode?.tag || '',
          value: checked,
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
};
</script>

<style scoped>
.toggle__line2 {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
.toggle__dot2 {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  /* top: -0.125rem;
  left: 0.15rem; */
  transition: transform 0.2s ease-in-out;
}

.dark .toggle__line2 {
  background-color: rgb(51, 51, 51);
  border: 0;
}
.dark .toggle__dot2 {
  @apply bg-base5;
  border-color: rgb(51, 51, 51);
}

input:checked ~ .toggle__dot2 {
  transform: translateX(100%);
}
.dot-shadow {
  box-shadow: 0rem 1px 4px 0rem rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0rem 1px 4px 0rem rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0rem 1px 4px 0rem rgba(0, 0, 0, 0.05);
}
.dark .dot-shadow {
  box-shadow: 0rem 2px 4px 0rem rgba(130, 130, 130, 0.1);
  -webkit-box-shadow: 0rem 2px 4px 0rem rgba(130, 130, 130, 0.1);
  -moz-box-shadow: 0rem 2px 4px 0rem rgba(130, 130, 130, 0.1);
}
</style>
