<template>
  <div v-if="splitLocation" class="flex">
    <div v-if="showConference" data-tooltip="meeting-conference">
      <hover-tool-tip
        label="Join video"
        selector='[data-tooltip="meeting-conference"]'
        placement="left"
        url="https://meetric.notion.site/Meeting-page-a744f1ebf776401d815ec4be504df6d8"
      ></hover-tool-tip>
      <a
        @click="onClick"
        class="cursor-pointer"
        target="_blank"
        :href="splitLocation.conference"
      >
        <!-- TODO: fix width on mobile -->
        <img
          v-if="!splitLocation.icon.includes('pin-icon')"
          :src="splitLocation.icon"
          class="inline opacity-75 hover:opacity-100 max-w-max"
          style="height: 1.7rem; width: auto"
        />
        <m-button
          v-if="splitLocation.icon.includes('pin-icon')"
          button-style="btn-quaternary"
        >
          <img
            :src="splitLocation.icon"
            class="inline"
            style="height: 1.1rem; width: auto"
          />
        </m-button>
      </a>
    </div>
    <div class="ml-1" v-if="showLocation" data-tooltip="meeting-location">
      <hover-tool-tip
        :label="!splitLocation.location ? 'Video link' : 'Meeting location'"
        selector='[data-tooltip="meeting-location"]'
        placement="left"
        url="https://meetric.notion.site/Meeting-page-a744f1ebf776401d815ec4be504df6d8"
      ></hover-tool-tip>
      <div v-if="!splitLocation.location">
        <a
          @click="onClick"
          class="cursor-pointer"
          target="_blank"
          :href="splitLocation.conference"
        >
          <m-button
            v-if="splitLocation.icon.includes('pin-icon')"
            button-style="btn-quaternary"
          >
            <img
              :src="splitLocation.icon"
              class="inline"
              style="height: 1.1rem; width: auto"
            />
          </m-button>
        </a>
      </div>

      <div v-else :title="'Meeting location: ' + splitLocation.location">
        <m-button
          class="cursor-pointer loc-button"
          segmentName="View location modal"
          button-style="btn-quaternary"
          @click="$emit('openlocation', locationText)"
        >
          <img
            :src="require('../../assets/locations/pin-icon.svg')"
            class="inline"
            style="height: 1.1rem; width: auto"
          />
        </m-button>
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/components/UI/MButton';
import HoverToolTip from '@/components/UI/HoverToolTip';
import { linkify } from '@/components/Utils';

export default {
  name: 'MLocation',
  components: {
    MButton,
    HoverToolTip,
  },
  props: {
    location: {
      type: String,
      required: false,
    },
    segmentName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showTooltipA: false,
      showTooltipB: false,
    };
  },
  computed: {
    locationText() {
      const conference = this.splitLocation?.conference || '';
      const location = this.splitLocation?.location || '';
      const addConferenceText =
        !this.showConference && conference ? conference + '<br>' : '';

      return linkify(addConferenceText + location);
    },
    showConference() {
      // show conference icon when:
      // have conference link && icon is not default
      return (
        this.splitLocation?.conference &&
        !this.splitLocation?.icon.includes('pin-icon')
      );
    },
    showLocation() {
      // show location, which is either default icon or text in location
      // there is conference icon && there is location
      return (
        (this.showConference && this.splitLocation.location) ||
        this.splitLocation.icon.includes('pin-icon')
      );
    },

    splitLocation() {
      const fullLocation = this.location;
      if (fullLocation) {
        let linkPatterns = new Object();
        let meetingIcon = new Object();
        let videoLink = '';
        let icon = require('../../assets/locations/pin-icon.svg');
        let locationRemainder = fullLocation;
        linkPatterns['meet'] = /https:\/\/meet.google.com\/[a-z-]+/;
        meetingIcon['meet'] = require('../../assets/locations/google-meet.png');
        linkPatterns['around'] = /https:\/\/meet.around.co\/[^\s]*/;
        meetingIcon['around'] = require('../../assets/locations/around.png');
        linkPatterns['hangouts'] = /https:\/\/hangouts.google.com\/[^\s]*/;
        meetingIcon[
          'hangouts'
        ] = require('../../assets/locations/hangouts.png');
        linkPatterns[
          'zoom'
        ] = /https:\/\/([a-z0-9-.]+)?zoom.(us|com.cn)\/j\/[^\s]*/;
        meetingIcon['zoom'] = require('../../assets/locations/zoom.png');
        linkPatterns[
          'teams'
        ] = /https:\/\/teams.microsoft.com\/l\/meetup-join\/[a-zA-Z0-9_%\/=\-\+\.?]+/; // eslint-disable-line
        meetingIcon[
          'teams'
        ] = require('../../assets/locations/microsoft-teams.png');
        linkPatterns['webex'] = /https:\/\/([a-z0-9.]+)?webex.com\/[^\s]*/;
        meetingIcon['webex'] = require('../../assets/locations/webex.png');
        linkPatterns['jitsi'] = /https:\/\/meet.jit.si\/[^\s]*/;
        linkPatterns['chime'] = /https:\/\/([a-z0-9-.]+)?chime.aws\/[^\s]*/;
        linkPatterns[
          'ringcentral'
        ] = /https:\/\/meetings.ringcentral.com\/[^\s]*/;
        linkPatterns[
          'gotomeeting'
        ] = /https:\/\/([a-z0-9.]+)?gotomeeting.com\/[^\s]*/;
        meetingIcon[
          'gotomeeting'
        ] = require('../../assets/locations/gotomeeting.png');
        linkPatterns[
          'gotowebinar'
        ] = /https:\/\/([a-z0-9.]+)?gotowebinar.com\/[^\s]*/;
        linkPatterns[
          'bluejeans'
        ] = /https:\/\/([a-z0-9.]+)?bluejeans.com\/[^\s]*/;
        meetingIcon[
          'bluejeans'
        ] = require('../../assets/locations/blue-jeans.png');
        linkPatterns['eight_x_eight'] = /https:\/\/8x8.vc\/[^\s]*/;
        linkPatterns['demio'] = /https:\/\/event.demio.com\/[^\s]*/;
        linkPatterns['join_me'] = /https:\/\/join.me\/[^\s]*/;
        linkPatterns[
          'zoomgov'
        ] = /https:\/\/([a-z0-9.]+)?zoomgov.com\/j\/[a-zA-Z0-9?&=]+/;
        linkPatterns['whereby'] = /https:\/\/whereby.com\/[^\s]*/;
        linkPatterns['uberconference'] = /https:\/\/uberconference.com\/[^\s]*/;
        linkPatterns['blizz'] = /https:\/\/go.blizz.com\/[^\s]*/;
        linkPatterns['vsee'] = /https:\/\/vsee.com\/[^\s]*/;
        linkPatterns['starleaf'] = /https:\/\/meet.starleaf.com\/[^\s]*/;
        linkPatterns['duo'] = /https:\/\/duo.app.goo.gl\/[^\s]*/;
        linkPatterns['voov'] = /https:\/\/voovmeeting.com\/[^\s]*/;
        linkPatterns['skype'] = /https:\/\/join.skype.com\/[^\s]*/;
        linkPatterns['skype4biz'] = /https:\/\/meet.lync.com\/[^\s]*/;
        linkPatterns['skype4biz_selfhosted'] = /https:\/\/meet\.[^\s]*/;
        for (var key in linkPatterns) {
          if (linkPatterns[key].test(fullLocation)) {
            const videoLinks = fullLocation.match(linkPatterns[key]);
            videoLink = videoLinks[0];
            locationRemainder = fullLocation.replace(linkPatterns[key], '');

            if (meetingIcon[key]) {
              icon = meetingIcon[key];
            }
            break;
          }
        }
        const result = {
          conference: videoLink,
          location: locationRemainder,
          icon: icon,
        };

        // console.log(result);
        return result;
      }
      return null;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');

      if (this.segmentName) {
        const props = {
          source: this.$parent.$parent.$vnode.tag,
          value: this.splitLocation.conference,
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
};
</script>

<style scoped></style>
