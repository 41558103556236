<template>
  <div
    class="card-shadow bg-white dark:bg-base5 lg:border-solid px-8 lg:px-2 lg:rounded-xl"
    style="padding-top:0.4rem;padding-bottom:0.4rem;"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(224, 241, 250, 0.5);
  -webkit-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(224, 241, 250, 0.5);
  -moz-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(224, 241, 250, 0.5);
}
.card-shadow:hover {
  box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(224, 241, 250, 2);
  -webkit-box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(224, 241, 250, 2);
  -moz-box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(224, 241, 250, 2);
}

/* Dark mode */
.dark .card-shadow {
  box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 30, 30, 0.5);
  -webkit-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 30, 30, 0.5);
  -moz-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 30, 30, 0.5);
}
.dark .card-shadow:hover {
  box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(41, 40, 40, 0.5);
  -webkit-box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(41, 40, 40, 0.5);
  -moz-box-shadow: 0rem 0.1rem 0.4rem 0rem rgba(41, 40, 40, 0.5);
}
</style>
