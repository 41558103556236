<template>
  <div class="flex items-center justify-center w-full main-wrapper">
    <label class="flex items-center cursor-pointer group">
      <div class="relative">
        <input
          type="checkbox"
          class="hidden"
          v-bind:checked="value"
          :disabled="disabled || pending"
          v-on:input="onInput($event.target.checked)"
        />
        <div
          class="flex circle-outer rounded-full border-2 border-solid border-grey4 hover:border-grey3 hover:border-opacity-75"
          style="width: 1.15rem; height: 1.15rem"
        >
          <div
            class="flex circle-inner rounded-full border-2 border-transparent"
            style="width: 100%; height: 100%"
          >
            <div
              class="flex circle-inner2 rounded-full"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
      </div>
      <div class="text-grey3 font-medium dark:hover:text-white">
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MRadioCheck',
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    pending: {
      type: Boolean,
      required: false,
      default: false,
    },
    segmentName: {
      type: String,
      required: false,
    },
    segmentLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onInput(checked) {
      this.$emit('input', checked);

      if (this.segmentName) {
        const props = {
          source: this.$parent.$parent.$vnode.tag,
          value: checked,
          label: this.segmentLabel || '',
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
};
</script>

<style scoped>
.circle-outer .circle-inner2 {
  @apply transition-colors duration-100 ease-linear;
}
input:checked ~ .circle-outer {
  @apply border-alertvalidation hover:border-alertvalidationlight;
}
input:checked ~ .circle-outer .circle-inner2 {
  @apply bg-alertvalidation;
}
</style>
