import { render, staticRenderFns } from "./MCard.vue?vue&type=template&id=0ed60e35&scoped=true&"
import script from "./MCard.vue?vue&type=script&lang=js&"
export * from "./MCard.vue?vue&type=script&lang=js&"
import style0 from "./MCard.vue?vue&type=style&index=0&id=0ed60e35&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed60e35",
  null
  
)

export default component.exports