<template>
  <h3 class="m-heading text-base1 text-2xl">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'Heading',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
