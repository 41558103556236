<template>
  <div class="bg-grey6 bg-opacity-75 animate-pulse"></div>
</template>

<script>
export default {
  name: 'MPlaceholder',
  components: {
  },
  props: {
  },
}
</script>
