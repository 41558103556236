<template>
  <transition name="modalappear">
    <div v-show="show" style="z-index: 100" class="fixed">
      <!-- Background -->
      <div
        @mousedown.self="closeModal"
        class="screen-overlay fixed inset-0 bg-grey1 bg-opacity-25 flex z-20 lg:pl-12"
      >
        <!-- Modal window -->
        <div
          class="bg-white dark:bg-base5 rounded-md z-20 shadow m-auto flex flex-col"
          :style="heightStyle"
          :class="widthClasses"
        >
          <!-- Title bar -->
          <div
            class="flex justify-between px-7 modaltitlebar font-medium rounded-t-md bg-base1 text-white dark:text-grey1"
            style="padding: 0.9rem 1.75rem 0.9rem 1.75rem"
          >
            <div>{{ title }}</div>

            <div
              @click="closeModal"
              class="cursor-pointer pl-2"
              style="opacity: 0.35"
            >
              <slot name="header_right">
                <span class="text-sm mr-2 hidden lg:inline">ESC to close</span>x
              </slot>
            </div>
          </div>

          <div class="flex-grow">
            <!--undo-->
            <slot name="top_modal" class="flex-grow"></slot>
          </div>

          <!-- Main content -->
          <div
            class="bg-white flex-grow flex-shrink dark:bg-base5 rounded-b-md overflow-y-auto"
            style="padding: 1.3rem 0rem 1.3rem 0rem"
          >
            <slot></slot>
          </div>

          <!-- Footer -->
          <div
            class="flex flex-col justify-center border border-solid border-l-0 border-r-0 border-b-0 border-grey6 px-7 py-5 overflow-hidden"
          >
            <div class="flex justify-between">
              <slot name="bottom_modal"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    widthClasses: {
      type: String,
      required: false,
      default: 'w-full lg:w-2/5',
    },
    heightStyle: {
      type: String,
      required: false,
      default: 'max-height: 80vh;',
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener('keydown', this.onEsc);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onEsc);
  },
  methods: {
    onEsc(e) {
      if (e.key == 'Escape') {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.modal-shadow {
  box-shadow: 0rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0rem 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.03);
}

/* Transition */
.modalappear-enter-active {
  transition: opacity 0.4s ease;
}
.modalappear-leave-active {
  transition: opacity 0.2s ease;
}
.modalappear-enter,
.modalappear-leave-to {
  opacity: 0;
}

.dark .modaltitlebar {
  background-color: rgb(0, 0, 0);
}

.dark .screen-overlay {
  background-color: rgba(20, 20, 20, 0.65);
}
</style>
