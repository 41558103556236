<template>
  <div class="div-select">
    <select
      @change="onChange($event.target.value)"
      :class="pending ? 'italic' : 'not-italic'"
      ref="selectbox"
    >
      <option
        v-if="placeholder"
        class="bg-white dark:bg-base5"
        value=""
        disabled
        v-bind:selected="value == ''"
      >
        {{ placeholder }}
      </option>
      <option
        v-for="(o, i) in options"
        class="bg-white dark:bg-base5"
        :key="'option' + i"
        :value="o.value"
        v-bind:selected="o.value === value"
      >
        {{ o.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'MSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    pending: {
      type: Boolean,
      required: false,
      default: false,
    },
    segmentName: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.setSelectWidth(this.options, val);
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.value) {
      this.setSelectWidth(this.options, this.value);
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
      this.setSelectWidth(this.options, value);

      if (this.segmentName) {
        const props = {
          source: this.$parent?.$parent?.$vnode?.tag || '',
          value: value,
        };
        window.meetric.track(this.segmentName, props);
      }
    },
    setSelectWidth(options, value) {
      const option = options.find((o) => o.value == value);

      const span = document.createElement('span');
      span.textContent = option.label;
      const w =
        measure(span, function (el) {
          return el.offsetWidth;
        }) + 26; // 26px is the arrow we add on the right

      this.$refs['selectbox'].style.width = w + 'px';
    },
  },
};

function measure(el, fn) {
  var pV = el.style.visibility,
    pP = el.style.position;

  el.style.visibility = 'hidden';
  el.style.position = 'absolute';

  document.body.appendChild(el);
  var result = fn(el);
  el.parentNode.removeChild(el);

  el.style.visibility = pV;
  el.style.position = pP;
  return result;
}
</script>
<style scoped>
.div-select {
  @apply flex flex-col justify-center relative;
}
.div-select:after {
  @apply absolute right-0 cursor-pointer -mr-1;
  margin-top: -0.3rem;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" stroke-width="2.5" stroke="%2300a4bd" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="16 15 12 19 8 15" /></svg>');
  pointer-events: none;
}
.div-select select {
  @apply pr-6 bg-transparent cursor-pointer;
  font-weight: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: transparent;
}
select::-ms-expand {
  display: none;
}
</style>
