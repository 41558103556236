<template>
  <div>
    <transition name="fade">
      <m-fullscreen-reward
        @close="onClose"
        v-show="show && loaded && giphy"
        :show="show"
      >
        <template v-slot:top>
          <div class="flex items-end justify-center relative">
            <div style="height: 70%" class="flex justify-center">
              <img
                v-if="giphy"
                class="hidden lg:block round-corners"
                :src="giphy.images.original.url"
                style="
                  height: 100%;
                  max-width: 100%;
                  object-fit: contain;
                  object-position: bottom;
                "
              />
              <img
                :src="require('@/assets/images/giphy_poweredby.png')"
                width="70"
                class="hidden lg:block absolute"
                style="bottom: 7px; right: 5px"
              />
              <img
                v-if="giphy"
                class="block lg:hidden"
                :src="giphy.images.original.url"
                style="
                  height: 100%;
                  max-width: 100%;
                  object-fit: contain;
                  object-position: bottom;
                "
              />
              <img
                :src="require('@/assets/images/giphy_poweredby.png')"
                width="50"
                class="block lg:hidden absolute"
                style="bottom: 7px; right: 5px"
              />
            </div>
          </div>
        </template>
        <template v-slot:bottom>
          <div class="flex-col text-center">
            <div class="text-3xl mt-8 lg:mt-8">
              You finished this meeting
              <span class="text-alertvalidation"
                >{{ getTimeText() }} early.</span
              >
            </div>
            <div class="text-2xl mt-2">{{ cheerText[cheerIdx] }}</div>
            <m-button
              class="mx-3 text-base mt-12"
              button-style="btn-primary"
              segmentName="Teleport to next meeting"
              icon="🛸"
              @click="goToNextMeeting"
            >
              Teleport to next meeting
            </m-button>

            <div class="font-normal text-lg mt-12 text-grey2">
              <span
                class="cursor-pointer underline opacity-75 hover:opacity-100"
                @click="onTwitterShare"
                >Share</span
              >
              this on
              <svg
                @click="onTwitterShare"
                class="cursor-pointer inline-block text-base2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                />
              </svg>
            </div>
          </div>
        </template>
      </m-fullscreen-reward>
    </transition>
  </div>
</template>

<script>
import MFullscreenReward from '../MFullscreenReward.vue';
import MButton from '@/components/UI/MButton';
import { getRandomGiphy } from '@/api/Giphy';

export default {
  name: 'MEarlyFinishOverlay',
  components: {
    MFullscreenReward,
    MButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fetch: {
      type: Number,
      default: 0,
    },
    minutesEarly: {
      type: Number,
      default: 1,
    },
    meetingId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      giphy: null,
      firstName: '',
      cheerIdx: 0,
      keywordIdx: 0,
      cheerText: [
        'Well done, you meeting rockstar 🤘',
        'The force is strong with you ⚔️',
        'If you were a lion, you’d be the pride of the pride 🦁',
        'If you were a pup, you’d be the pick of the pack 🐶',
      ],
      giphyWordList: [
        'celebration',
        'congratulations',
        'thumbs up',
        'high five',
        'we did it',
        // 'well done',
        'you rock',
        "you'r awesome",
      ],
    };
  },
  watch: {
    show: function (newVal) {
      if (newVal && !this.giphy) {
        this.init();
      }
    },
    fetch: function (newVal) {
      if (newVal) {
        this.init();
      }
    },
  },
  mounted() {
    this.firstName = this.$gAuth?.basicProfile?.getGivenName();
    if (this.firstName)
      this.cheerText.push(`Bravo mon ami(e) ${this.firstName} 👏`);

    this.giphyDefault = {
      bitly_url: '',
      images: {
        original: { url: require('@/assets/images/rewardFallback.gif') },
      },
    };
  },
  computed: {},

  methods: {
    goToNextMeeting() {
      this.$router.replace({
        name: 'now',
        params: { ignoreIds: [this.meetingId] },
      });
    },
    init() {
      this.keywordIdx = getRandomInt(this.giphyWordList.length);
      this.cheerIdx = getRandomInt(this.cheerText.length);
      this.getGiphy();
    },
    onTwitterShare() {
      let text = `Love @meetricapp celebration for wrapping up meetings earlier: "${
        this.firstName ? this.firstName + ', c' : 'C'
      }ongratz for finishing your meeting ${this.getTimeText()} early - ${
        this.cheerText[this.cheerIdx]
      }"`;
      let url = `https://twitter.com/intent/tweet?url=${this.giphy.bitly_url}&text=${text}`;
      window.open(url, '_blank');
      // console.log('twitter', url);

      window.meetric.track('Share Giphy', { where: 'twitter' });
    },
    getGiphy() {
      const keyword = this.giphyWordList[this.keywordIdx];

      // default giphy in case there is a problem
      this.giphy = null;

      getRandomGiphy(keyword).then((r) => {
        if (r?.meta?.status == 200) {
          if (r.data?.images?.original) this.giphy = r.data;
          else this.giphy = this.giphyDefault;
        }
        this.loaded = true;
      });
    },
    getTimeText() {
      return `${this.minutesEarly} minute${this.minutesEarly > 1 ? 's' : ''}`;
    },
    onClose() {
      this.$emit('close');
    },
  },
};

// get random 0 <= number < max
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
</script>

<style scoped>
/* .circle-cut {
  clip-path: circle(190px at center);
} */
.round-corners {
  clip-path: inset(0 0 0 0 round 10px);
}

.fade-enter-active {
  transition: opacity 0.8s;
}
.fade-enter {
  opacity: 0;
}
</style>
<style></style>
