export default {
  slackLink: {
    filter: function (node, options) {
      return (
        options.linkStyle === 'slack' &&
        node.nodeName === 'A' &&
        node.getAttribute('href')
      );
    },

    replacement: function (content, node) {
      var href = node.getAttribute('href');
      return `<${href}|${content}>`;
    },
  },

  // Slack doesn't support headings, so we'll just make them all bold
  heading: {
    filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    replacement: (content, node) => {
      if (node.parentNode.getAttribute('data-type') == 'agenda_item') {
        return `*${content}*`;
      }
      return `\n\n*${content}*\n`;
    },
  },

  paragraph: {
    filter: 'p',
    replacement: function (content, node) {
      if (
        node.parentNode.getAttribute('data-type') == 'action_item' ||
        node.parentNode.getAttribute('data-type') == 'agenda_item'
      ) {
        // console.log('parent is action');
        return content;
      } else if (node.parentNode.nodeName === 'LI') {
        return content;
      }

      return content + '\n';
      // return '\n' + content + '\n';
    },
  },
  actionItem: {
    filter: function (node) {
      return node.getAttribute('data-type') == 'action_item';
    },

    replacement: function (content, node) {
      return (
        (node.getAttribute('data-completed') == 'true' ? '☑︎ ' : '☐ ') +
        content +
        '\n'
      );
    },
  },
  agendaItem: {
    filter: function (node) {
      return node.getAttribute('data-type') == 'agenda_item';
    },

    replacement: function (content, node) {
      return (
        (node.getAttribute('data-completed') == 'true' ? '⦿ ' : '⦾ ') +
        content +
        '\n'
      );
    },
  },
  agendaItemWrapper: {
    filter: function (node) {
      return node.nodeName === 'DIV' && node.getAttribute('data-agendadepth');
    },

    replacement: function (content, node) {
      const number = Number(node.getAttribute('data-agendadepth'));
      // normalize depth
      // -2 so top level list is actually 0
      const depth = isNaN(number) ? 0 : number;
      return '  '.repeat(depth) + content;
    },
  },

  list: {
    filter: ['ul', 'ol'],

    replacement: function (content, node) {
      var parent = node.parentNode;
      if (parent.nodeName === 'LI' && parent.lastElementChild === node) {
        return '\n' + content;
      } else {
        return '\n' + content + '\n';
      }
    },
  },

  listItem: {
    filter: 'li',
    replacement: function (content, node, options) {
      content = content
        .replace(/^\n+/, '') // remove leading newlines
        .replace(/\n+$/, '\n') // replace trailing newlines with just a single one
        .replace(/\n/gm, '\n    '); // indent
      var prefix = options.bulletListMarker + '  ';
      var parent = node.parentNode;
      if (
        node.firstChild?.getAttribute('data-type') == 'action_item' ||
        node.firstChild?.getAttribute('data-type') == 'agenda_item'
      ) {
        prefix = '';
      } else if (parent.nodeName === 'OL') {
        var start = parent.getAttribute('start');
        var index = Array.prototype.indexOf.call(parent.children, node);
        let specialItemsBefore = 0;
        // count specialItems before this node to adjust list counter
        for (let i = 0; i < index; i++) {
          if (
            parent.children[i].firstChild?.getAttribute('data-type') ==
              'action_item' ||
            parent.children[i].firstChild?.getAttribute('data-type') ==
              'agenda_item'
          ) {
            specialItemsBefore++;
          }
        }

        prefix =
          (start
            ? Number(start) + index - specialItemsBefore
            : index + 1 - specialItemsBefore) + '. ';
      }
      return (
        prefix +
        content.trim() +
        (node.nextSibling && !/\n$/.test(content) ? '\n' : '')
      );
    },
  },

  // Slack doesn't support images, so just show the link
  images: {
    filter: 'img',
    replacement: function (content, node) {
      const parent = node.parentNode;
      if (parent && parent.nodeName === 'A') {
        return node.alt || node.src;
      }
      return ` <${node.src}|${node.alt || 'Image'}>`;
    },
  },

  highlightedCodeBlock: {
    filter: function (node) {
      var firstChild = node.firstChild;
      return (
        node.nodeName === 'DIV' &&
        /highlight-(?:text|source)-([a-z0-9]+)/.test(node.className) &&
        firstChild &&
        firstChild.nodeName === 'PRE'
      );
    },
    replacement: function (content, node, options) {
      return (
        '\n\n' +
        options.fence +
        '\n' +
        node.firstChild.textContent +
        '\n' +
        options.fence +
        '\n\n'
      );
    },
  },
};
