<template>
  <div
    class="fixed inset-0 justify-center flex main-overlay bg-basebackground text-base1 font-semibold"
    id="meetric-reward"
    v-show="showLocal"
  >
    <div class="flex-col flex-grow z-10 m-4">
      <div class="flex justify-center flex-grow h-3/6 lg:h-3/5 z-10">
        <div class="flex justify-center flex-grow mb-0">
          <slot name="top"></slot>
        </div>
      </div>
      <div class="flex justify-center z-10 px-10 lg:px-0">
        <slot name="bottom"></slot>
      </div>
      <a
        class="absolute top-0 right-0 text-grey3 text-lg p-5 pt-3 cursor-pointer select-none font-medium z-20"
        @click="onClose"
      >
        ESC to close &#x2715;
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MFullscreenReward',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLocal: true,
    };
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        this.showLocal = true;
        // console.log('show true');
        document.addEventListener('keydown', this.onKeyPress);
      } else {
        // console.log('show false');
        document.removeEventListener('keydown', this.onKeyPress);
      }
    },
  },
  mounted() {
    // document.addEventListener('keydown', this.onKeyPress);
  },
  beforeDestroy() {
    // document.removeEventListener('keydown', this.onKeyPress);
  },
  methods: {
    onClose() {
      // console.log('close');
      this.showLocal = false;
      this.$emit('close');
    },
    onKeyPress(e) {
      if (e.key == 'Escape') {
        this.onClose();
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.main-overlay::before {
  content: '';
  background-image: url('~@/assets/images/confetti.png');
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.08;
}
</style>
